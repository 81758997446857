import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "margin-top10px margin-bottom10px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.$wordList.Notice,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
      onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit')))
    }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_1, _toDisplayString(_ctx.$wordList.InsUpdateSubmitNotice), 1),
        _createVNode(_component_el_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.ChangeLog
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.changeLog,
                  disabled: "",
                  rows: "5",
                  "auto-complete": "off",
                  type: "textarea"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}