
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['close', 'submit'],
    props: {
        changeLog: {
            type: String,
            default: ''
        }
    }
});
